.map-container {
    width: 50%;
    height: 70%;
}

@media screen and (max-width: 30em){
    .map-container {
      width: 80%;
      height:90%;
    }
  }