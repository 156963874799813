.directions-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #1a73e8;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media screen and (max-width: 30em){
    .directions-button {
      width: 60%;
      height:20%;
    }
  }

  @media screen and ((max-height: 30em) and (orientation: landscape)){
    .directions-button {
      font-size:smaller;
      width: 65%;
      height: 20%;
    }
  }